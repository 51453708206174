(function () {
	document.addEventListener("DOMContentLoaded", function () {
		btnEvent();
		flickity();
		smoothScroll();
		urlHashScroll();

		window.addEventListener("scroll", function () {
			currentNav();
			lgNavScroll();
		});
	});
	const header = document.querySelector(".global-header.lg");
	const lgList = document.querySelectorAll(".global-header.lg .inner ul li a");
	const targetSection = document.querySelectorAll(".target-section");
	const windowWidth = window.innerWidth;
	const responsiveThreshold = 768;
	const lgHeaderHeight = 73;
	const smHeaderHeight = 50;
	let headerHeight;
	if (windowWidth > responsiveThreshold) {
		headerHeight = lgHeaderHeight;
	} else {
		headerHeight = smHeaderHeight;
	}

	function urlHashScroll() {
		const url = location.hash;
		const anchor = document.querySelector("#terms");
		const options = {offset: headerHeight, speed:800, easing: 'easeInOutQuint'};
		if (url === "#terms") {
			const scroll = new SmoothScroll();
			scroll.animateScroll(
				anchor, "", options
			);
		}
	}

	function btnEvent() {
		const btn = document.querySelector("#btn");
		const nav = document.querySelector("#nav");
		const smList = document.querySelectorAll("#nav ul li a");
		btn.onclick = function () {
			this.classList.toggle("open");
			nav.classList.toggle("open");
		};
		for (let i = 0; i < smList.length; i++) {
			smList[i].onclick = function () {
				btn.classList.remove("open");
				nav.classList.remove("open");
			}
		}
	}

	function currentNav() {
		const scrollVal = window.pageYOffset;
		const targetSectionRange = [];
		for (let i = 0; i < targetSection.length; i++) {
			const targetSectionTop = targetSection[i].getBoundingClientRect().top + scrollVal - lgHeaderHeight;
			const targetSectionBottom = targetSectionTop + targetSection[i].clientHeight - 1;
			targetSectionRange.push([targetSectionTop, targetSectionBottom]);
			if (scrollVal < targetSectionRange[0][0]) {
				lgList[0].removeAttribute('class');
			} else if (scrollVal >= targetSectionRange[i][0] && scrollVal <= targetSectionRange[i][1]) {
				const node = Array.prototype.slice.call(lgList);
				node.forEach(function (value) {
					value.classList.remove("current");
				});
				lgList[i].classList.add("current");
			}
		}
		if (scrollVal > targetSectionRange[targetSection.length - 1][1]) {
			lgList[targetSection.length - 1].classList.remove('current');
		}
	}

	function lgNavScroll() {
		header.style.left = -window.pageXOffset + "px";
	}

	function flickity() {
		const elem = document.getElementById('carousel');
		const flkty = new Flickity(elem, {
			cellAlign: 'left',
			autoPlay: 3000,
			wrapAround: true,
			accessibility: false,
			prevNextButtons: false
		});
	}

	function smoothScroll() {
		const scroll = new SmoothScroll('a[href*="#"]', {
			offset: headerHeight,
			speed: 800,
			easing: 'easeInOutQuint',
			updateURL: false,
		});
	}
}());

$(function(){
	if( $(".movie_play").length ){
		$('.movie_play').boxer({videoWidth:960});	}
});
